<template>
    <v-content style="width: 100%; height: 100%">
        <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                top
        >
            {{ t(text) }}
            <v-btn
                    flat
                    @click="snackbar = false"
            >
                {{t('Close')}}
            </v-btn>
        </v-snackbar>
        <v-container
                fluid
                fill-height
        >
            <v-layout
                align-center
                justify-center
            >
                <v-flex
                    xs12
                    sm8
                    md4
                >
                    <v-card class="elevation-12">
                        <v-toolbar
                            color="primary"
                            dark
                            flat
                        >
                            <v-toolbar-title>{{t('Login')}}</v-toolbar-title>
                            <v-spacer>
                            </v-spacer>
                        </v-toolbar>
                        <v-form
                            ref="form"
                            class="text-xs-right"
                            @submit.prevent="submitLoginForm"
                        >
                            <v-card-text>

                                <v-text-field
                                    :label="t('Username')"
                                    name="Username"
                                    prepend-icon="person"
                                    :rules="[rules.required, rules.username]"
                                    v-model="username"
                                >
                                </v-text-field>

                                <v-text-field
                                    id="password"
                                    :label="t('Password')"
                                    name="password"
                                    prepend-icon="lock"
                                    type="password"
                                    v-model="password"
                                    :rules="[rules.required,rules.counter]"
                                >
                                </v-text-field>

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" type="submit">{{t('Login')}}</v-btn>
                            </v-card-actions>
                            <v-card-text class="d-flex justify-content-between mt-2">
                                <router-link class="text-xs-left" to="/register">{{t('Register now!')}}</router-link>
                                <router-link class="text-xs-right" to="/forgot">{{t('Forgot Password?')}}</router-link>
                            </v-card-text>
                        </v-form>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Login",
        data() {
            return {
                snackbar: false,
                text: 'Oops... Something went wrong',
                timeout: 5000,
                title: 'Preliminary report',
                username: '',
                email: '',
                password: '',
                redirect_to_resend: false,
                verifyRegisterLoading: false,
                rules: {
                    required: value => !!value || this.$translate.locale['Required.'],
                    username: value => (value.length >= 4 && value.length <= 150) || this.$translate.locale['Username between the 4 - 150 characters'],
                    counter: value => value.length >= 8 || this.$translate.locale['Min 8 characters'],
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || this.$translate.locale['Invalid e-mail.']
                    },
                },
            }
        },
        methods: {
            submitLoginForm() {
                if (!this.$refs.form.validate()) {
                    this.text = "Fill the form correctly";
                    this.snackbar = true
                    return
                }
                // if user completes captcha, proceedLoginIfCaptchaIsGood is called
                // this.$refs.recaptcha.execute();

                this.proceedLoginIfCaptchaIsGood(null);
            },
            proceedLoginIfCaptchaIsGood(token) {
                const data = {
                    password: this.password,
                    username: this.username,
                    recaptcha: token,
                };
                this.$store.dispatch('LOGIN', data).then(() => {
                    this.$parent.$parent.flag();
                    this.$router.push('/dashboard');
                }).catch((error) => {
                    this.redirect_to_resend = error.response.data.redirect;
                    if (this.redirect_to_resend) {
                        this.text = error.response.data.detail;
                        this.snackbar = true;
                        this.$router.push('/sendEmail')
                    } else {
                        this.text = error.response.data.detail;
                        this.snackbar = true;
                    }
                });
            }
        },
        created() {
            if (this.$route.params.token) {
                this.verifyRegisterLoading = true;
                axios.get(`/api/register_activate/${this.$route.params.token}/`)
                    .then((response) => {
                        if (response.status === 200) {
                            this.verifyRegisterLoading = false;
                            this.name = response.username;
                            this.text = "Registration successful";
                            this.snackbar = true;
                        }
                    }).catch((error) => {
                        this.verifyRegisterLoading = false;
                        this.text = error.response.data.details;
                        this.snackbar = true;
                });
            }
        },
    beforeMount() {
        let language = localStorage.getItem('currentLanguage');

        if (!language) 
            language = "english";

        this.$translate.setLang(language);
    },
}
</script>

<style scoped>

</style>
